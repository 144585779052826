import { memo, forwardRef } from "react";
import { MdsTextField, MdsTextFieldProps } from "@/design-system/components/text-field";
import { MdsIconKind } from "@/design-system/components/icon";
import { mdsBorderRadius } from "@/design-system/foundations";
import styled from "@emotion/styled";

export interface MdsFilterFieldProps
  extends Pick<
    MdsTextFieldProps,
    | "name"
    | "onFocus"
    | "onBlur"
    | "onChange"
    | "onEnterKeyPress"
    | "onEscapeKeyPress"
    | "readOnly"
    | "disabled"
    | "value"
    | "placeholder"
    | "autoFocus"
    | "onClearText"
  > {}

export const MdsTextFilterField = memo(
  forwardRef<HTMLInputElement, MdsFilterFieldProps>((props, ref) => {
    const {
      value,
      name,
      disabled,
      onFocus,
      onBlur,
      onChange,
      onEnterKeyPress,
      onEscapeKeyPress,
      onClearText,
      readOnly,
      placeholder,
    } = props;

    return (
      <TextField
        ref={ref}
        name={name}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        onEnterKeyPress={onEnterKeyPress}
        onEscapeKeyPress={onEscapeKeyPress}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        iconKind={MdsIconKind.Search}
        showClearTextButton={true}
        onClearText={onClearText}
      />
    );
  })
);

const TextField = styled(MdsTextField)({
  height: 32,
  width: 240,
  borderRadius: mdsBorderRadius().maximum,
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  alignItems: "center",
  background: "transparent",

  "> input": {
    // Safari needs this, otherwise the input and later elements will overflow under the next element.
    width: 240 - 62,
    background: "transparent",
  },
});
